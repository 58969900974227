<template>
  <div class="entrustChildInfo">
    <h3>雅曼社区婴幼儿临时托管说明</h3>
    <p>
      为更好的服务社区婴幼儿家庭，解决婴幼儿临时无人照顾难题，雅曼社区推出临时性托管服务，具体事宜如下：
    </p>
    <p>一、托管对象：雅曼社区管辖范围内6个月-3岁婴幼儿。（家长不陪同）</p>
    <p>
      二、托管时间：一般为临时性托管，一次托管1-3小时，一般不超过3小时。每位婴幼儿每月累计托管不超过24小时。
    </p>
    <p>三、托管地点：木木橙港隆园（嘉里玫瑰苑沿街）或雅曼社区党群服务中心</p>
    <p>四、托管须知：</p>
    <p>
      1、临时托半日托入园需持健康体检保健册，疫苗接种复印件和监护人身份证明（户口页复印件）。
    </p>
    <p>
      2、携带幼儿临时所需生活用品（奶粉，尿不湿，湿巾纸巾，水杯，依恋物，不允许携带其他类型食品药品）。
    </p>
    <p>3、离园前检查好个人所有物品。</p>
    <p>
      4、社区内家庭预约临时托，需在社区“雅曼助手”小程序“社区托幼”模块内预约为有效。
    </p>
    <p>咨询电话：15336618108（周老师）</p>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "entrustChildInfo",
  data() {
    return {
      oData: "",
    };
  },
  components: {},
  destroyed() {},
  filters: {
    dateFormat: function (value) {
      if (value) {
        return moment(value).format("HH:mm");
      }
      return moment(new Date()).format("HH:mm");
    },
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.entrustChildInfo {
  padding: 30px;
  h3 {
    font-size: 36px;
    text-align: center;
    margin: 20px 0;
  }
  p {
    font-size: 30px;
    text-indent: 2em;
    margin: 20px 0;
  }
}
</style>
